
import React, {useEffect, useState} from 'react'
import api from '../util/api'
import Navbar from '../component/StackNavBar'
import Loader from "react-loader-spinner";
import './style.css'
import Banner from './Banner2.png'

import ClipLoader from "react-spinners/HashLoader";


export default function HomeBoard() {
    const [data, setData] = useState(undefined)
    const [touch, setTouch] = useState(undefined);
    const [formData, setFormData] = useState({ job: '', location: '' });

    const [jobs, setJobs] = useState(undefined);
    const [Loading, setLoading] = useState(false);


    const { job, location } = formData;
    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(async()=>{

        let data = await api.get('/job/stackoverflow/api/v1/remote');   
        var dataset = data.data;
        setData(dataset)
        setTouch(data[0])


    },[])


  


   if(data === undefined){
       return(
           <div>
               <Navbar/>
               <div className="text-center">
            <div style={{marginTop: '20vh'}}>
                <Loader
                    type="Audio"
                    color="#C51162"
                    height={140}
                    width={140}
                    timeout={1000000} //3 secs
                />    </div>
                        </div>
           </div>
       )

   }
   else{

    var Trending = data.slice(0, 15);
    var Older = data.slice(16, 50);



    async function handler(e){
        e.preventDefault();
        setLoading(true);

        var BaseUrl = "/job/stackoverflow/job/find";
        let sendData =  await api.post(BaseUrl, formData);

        setJobs(sendData.data)
        setLoading(false);
    }


    

    

    return(
        <div className="">
        <Navbar/>
        <div className="x" style={{backgroundImage: `url(${Banner})`}}>
        <br/>
        <br/>

         <div className="container" >
         <h1 style={{fontWeight: '900'}} className="display-1 text-dark text-iom"><span className="text-danger">Remote</span> Jobs <span className="text-warning">worldwide </span></h1>
        
        <p className="lead text-dark" style={{fontWeight: '500'}} >Proudly Sponsoring Stackoverflow</p>
        <br/>
        <br/>

        <form onSubmit={handler}>
        <div className="row">

            <div className="col-xl-7"> <input name="job" value={job} onChange={onChange} placeholder="Search all jobs e.g Software Engineering, Frontend Developer...." style={{height: '48px'}} className="form-control"/><br/></div>
            <div className="col-xl-4"> <input name="location" value={location} onChange={onChange} placeholder="Enter Location e.g Newyork USA, etc.." style={{height: '48px'}} className="form-control"/><br/></div>
            <div className="col-xl-1">   
            
           
             <button type="submit" className="btn btn-lg btn-primary">Search</button>
             
             
             </div>
        
        </div>
           
            

           
         
        </form>

        {Loading === true ? <div className="text-center mx-auto">
        <div style={{position: 'absolute', zIndex: '300', width: '90%'}}  className="text-center" >
        <div>
        <ClipLoader color="red"  size={250} />
        </div>
        </div>
         </div> : <div> </div>}
        
        <br/>
        <br/>
        <br/>
        <div className="row">
            <div className="col-xl-3 col-md-3 col-lg-3">
            <div class="card alert-info border-primary  mb-3 text-center">
            
            <div class="card-body text-primary">
                <h5 class="card-title">Sponsorship</h5>
                <img style={{width: '80px'}} src="/sponsor.svg" alt=""/>
                <br/>
                <br/>
                <a href="/jobs/sponsorship" className="btn btn-outline-dark">Browse Jobs</a>
            </div>
            </div>
            </div>

            <div className="col-xl-3 col-md-3 col-lg-3">
            <div class="card  border-primary mb-3 text-center">
            
            <div class="card-body text-success">
                <h5 class="card-title">Relocation</h5>
                <img style={{width: '80px'}} src="/travel.svg" alt=""/>
                <br/>
                <br/>
                <a href="/jobs/relocation" className="btn btn-dark">Browse Jobs</a>
            </div>
            </div>
            </div>


            <div className="col-xl-3 col-md-3 col-lg-3">
            <div class="card alert-primary border-primary mb-3 text-center">
            
            <div class="card-body text-dark">
                <h5 class="card-title">Remote</h5>
                <img style={{width: '80px'}} src="/remote.svg" alt=""/>
                <br/>
                <br/>
                <a href="/jobs/remote" className="btn btn-outline-dark">Browse Jobs</a>
            </div>
            </div>
            </div>

            <div className="col-xl-3 col-md-3 col-lg-3">
            <div class="card alert-warning border-primary mb-3 text-center">
            
            <div class="card-body text-danger">
                <h5 class="card-title">Equity</h5>
                <img style={{width: '80px'}} src="/equity.svg" alt=""/>
                <br/>
                <br/>
                <a href="/jobs/equity" className="btn btn-dark">Browse Jobs</a>
            </div>
            </div>
            </div>
        




        </div>


        
        
        <br/>
        </div>
        </div>


       {/** 
        This is the Search result Components
        **/} 

    <div className="container">
        {jobs != undefined ? <div> 
            <br/>
            <br/>
            <h1 style={{fontWeight: '500'}} className="display-5">Search Results</h1>
            <br/>
            <br/>


            {jobs.length === 0 ? <div> <p className="lead"> No jobs Available </p><br/> <br/> <br/> </div> : <div> </div>}

            <div className="row">
             {jobs.map((item)=>{
                 return <div className="col-xl-3">
                    <div className="card" style={{margin: '20px 0px'}}>
                    <div class="card-header">
                    
                    {!item.location ? <div>
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; Remote </div>
                    </div> : 
                   
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; {item.location.$t} </div>
                   
                   }
                        
                    </div>
                    <div class="card-body" style={{height: '250px'}}>
                    <h5 class="card-title">
                    {((item.title).split("(").join(" ")).split(")").join(" ")}
                    </h5>
                <p class="card-text">

                {Array.isArray(item.category) === false ? <div>
                    <button style={{margin: '2px'}} className="btn btn-sm btn-outline-dark">{item.category}</button>
                    </div> : <div>
                    {item.category.map((x)=>{
                        return <button style={{margin: '2px'}} className="btn btn-sm btn-outline-dark">{x}</button>
                    })}
                    
                    </div>}

                    
                </p>
               
            </div>
           
            <div className="card-footer bg-white">
                 <a href={"/jobs/stackoverflow/"+ item.pubDate} class="btn  btn-lg btn-primary">View Job</a>
                 </div>
                    </div>
                 
                
                  </div>
             })}
         </div>
        
        
         </div> : 
        
        <div> </div>}
    </div>













        <div className="container">
         <br/>
         <h1 style={{fontWeight: '500'}} className="display-5">Latest Jobs</h1>
         <br/>
         <div className="row">
             {Trending.map((item)=>{
                 return <div className="col-xl-3">
                    <div className="card" style={{margin: '20px 0px'}}>
                    <div class="card-header">
                    
                    {!item.location ? <div>
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; Remote </div>
                    </div> : 
                   
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; {item.location.$t} </div>
                   
                   }
                        
                    </div>
                    <div class="card-body" style={{height: '250px'}}>
                    <h5 class="card-title">
                    {((item.title).split("(").join(" ")).split(")").join(" ")}
                    </h5>
                <p class="card-text">
                {item.category.map((x)=>{
                    return <button style={{margin: '2px'}} className="btn btn-sm btn-outline-dark">{x}</button>
                })}
                </p>
               
            </div>
           
            <div className="card-footer bg-white">
                 <a href={"/jobs/stackoverflow/"+ item.pubDate} class="btn  btn-lg btn-primary">View Job</a>
                 </div>
                    </div>
                 
                
                  </div>
             })}
         </div>
                  

          
          </div>


             <div className="container">
             <br/>
          <h1 style={{fontWeight: '500'}} className="display-6">Trending Jobs</h1>
          <br/>
         <br/>
         <div className="row">
             {Older.map((item)=>{
                 return <div className="col-xl-3">
                    <div className="card" style={{margin: '20px 0px'}}>
                    <div class="card-header">
                    
                    {!item.location ? <div>
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; Remote </div>
                    </div> : 
                   
                    <div style={{fontWeight: '500', fontSize: '16px'}} ><img style={{width: '40px'}} src="/location.svg" alt=""/> &nbsp; {item.location.$t} </div>
                   
                   }
                        
                    </div>
                    <div class="card-body" style={{height: '250px'}}>
                    <h5 class="card-title">
                    {((item.title).split("(").join(" ")).split(")").join(" ")}
                    </h5>
                <p class="card-text">
                {item.category.map((x)=>{
                    return <button style={{margin: '2px'}} className="btn btn-sm btn-outline-dark">{x}</button>
                })}
                </p>
               
            </div>
           
            <div className="card-footer bg-white">
                 <a href={"/jobs/stackoverflow/"+ item.pubDate} class="btn  btn-lg btn-primary">View Job</a>
                 </div>
                    </div>
                 
                
                  </div>
             })}
         </div>
                  
</div>
          




        </div>
    )
   }
}
