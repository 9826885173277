import React from 'react'
import {Link} from 'react-router-dom'

export default function Navbar() {
    return (
        <div><div class=" flex-column flex-md-row align-items-center p-3 px-md-4 mb-3  border-bottom box-shadow">
       <nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand" href="/">
  <img style={{width: '280px'}} src="/StackLogo.PNG" alt=""/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ms-auto">
      <li class="nav-item active">
        <a class="nav-link p-2 text-dark" href="#">Stackoverflow Community</a>
      </li>
      <li class="nav-item">
      <a class="nav-link p-2 text-dark" href="#"> Jobs</a>
      </li>
      <li class="nav-item">
        <a class="nav-link p-2 text-dark" href="#">Sponsoring</a>
      </li>
      
      <li class="nav-item">
        <a style={{marginLeft: '20px'}} class="btn btn-outline-success btn-lg " href="https://github.com/DaimDN"> Sponsor me </a>
      </li>
      
    </ul>
  </div>
</nav>

       </div>

      </div>
            
   
    )
}