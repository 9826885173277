import React, {useEffect, useState} from 'react'
import Navbar from '../component/StackNavBar'
import {useParams} from 'react-router-dom'
import api from '../util/api'
import Loader from "react-loader-spinner";
export default function SearchQuery() {
    let parameter = useParams();
    const [fetch, setFetch] = useState(undefined);


    useEffect(async()=>{
            var BaseUrl = "/job/stackoverflow/job/"+ parameter.id;
            let data = await api.get(BaseUrl);
            setFetch(data.data);
    
    },[])


    if(fetch == undefined){
        return(
            <div>
                 <Navbar/>
                 <div className="text-center">
            <div style={{marginTop: '20vh'}}>
                <Loader
                    type="Audio"
                    color="#C51162"
                    height={140}
                    width={140}
                    timeout={3000} //3 secs
                />    </div>
                        </div>
            </div>
        )

    }
    else{
        var categories = fetch[0].category;
        var array = [];

        if(Array.isArray(categories)){

            array = categories.map((item)=>{
                return <div className=""> <button className="btn  btn-outline-dark">{item} </button> &nbsp; </div>
            })
        }
        
        
        
        

     
        return (
            <div>
            <Navbar/>
            <div className="container">
               {fetch.map((item)=>{
                   return <div >
                   <h1>{((item.title).split(")").join(" ")).split("(").join("")}</h1>
                   
                   <br/>

                   {Array.isArray(item.category) === false ? <div className="">
                    <button style={{margin: '2px'}} className="btn btn-sm btn-outline-dark">{item.category} </button>
                    </div> : <div>
                    <div className="d-xl-flex d-lg-flex d-md-flex">
                    {item.category.map((z)=>{
                        return <div style={{padding: '5px 0px'}}> <button className="btn btn-outline-dark">{z} </button> &nbsp; </div>
                    })}
                    </div>
                    
                    
                    </div>}

                  
                  
                 
                   <br/>
                   {!item.location ? <div>
                    <div style={{fontWeight: '500', fontSize: '30px'}} ><img style={{width: '50px'}} src="/location.svg" alt=""/> &nbsp; Remote </div>
                    </div> : 
                   
                    <div style={{fontWeight: '500', fontSize: '30px'}} ><img style={{width: '50px'}} src="/location.svg" alt=""/> &nbsp; {item.location.$t} </div>
                   
                   }
                   <br/>
                   
                   
                   <a className="btn btn-lg btn-primary" href={item.link}>Apply Now</a>
                   <br/>
                    <br/>
                    
                   
                   <br/>
                  
                   <p className="lead">
                   <div className="lead" dangerouslySetInnerHTML={{__html: item.description}}></div>
                   </p>
                    </div>
               })}
            </div>
                        
            </div>
        )
    }

   
}
