import React from 'react'
import Navbar from '../component/defaultnav'

export default function Error() {
    return (
        <div>
        <Navbar/>
        
        <div className="container text-center">
        <h1 className="display-6">Something went wrong...</h1>
        <br/>
        <img style={{width: '20%'}} src="/error.svg"/>
        <br/>
        <br/>
        <a href="/" className="btn btn-outline-primary btn-lg">Go Back</a>
        
        </div>    
        </div>
    )
}
