import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, 
    useHistory
  } from "react-router-dom";
  import React, {Fragment} from 'react'
  import GithubBoard from '../github/GithubBoard'
  import JobBoard from '../github/JobBoard'
  import SearchResult from '../github/SearchResult'
  import HomeBoard from '../stackoverflow/HomeBoard'
  import App from './App'
  import SearchQuery from '../stackoverflow/SearchQuery'
  import Sponsorship from '../stackoverflow/Sponsorship'
  import Error from './Error'
  import Relocation from '../stackoverflow/Relocation'
  import Equity from '../stackoverflow/Equity'
  import Remote from '../stackoverflow/remote'

 

  export default function Routing (){
      return(
        <Router>
        <Fragment>     
          <Switch>    
          <Route  exact path="/" component={App} /> 
            <Route  exact path="/jobs/github/" component={GithubBoard} />  
            <Route  exact path="/jobs/stackoverflow/" component={HomeBoard} /> 
            <Route  exact path="/jobs/sponsorship/" component={Sponsorship} />  
            <Route  exact path="/jobs/relocation/" component={Relocation} />   
            <Route  exact path="/jobs/equity/" component={Equity} />  
            <Route  exact path="/jobs/remote/" component={Remote} />     
            <Route  exact path="/jobs/github/:id" component={JobBoard} /> 
            <Route  exact path="/jobs/stackoverflow/:id" component={SearchQuery} />
            <Route  exact path="/job/github/search/:location/:id" component={SearchResult} /> 
            <Route  exact path="*" component={Error} />         
            
          </Switch>
        </Fragment>
      </Router>  
      )
  }

