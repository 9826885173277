import React from 'react'
import Navbar from '../component/defaultnav'

export default function App() {
    return (
        <div>
        <Navbar/>  
   
            <div className="container">
            
            <div class="card">
                <div class="card-header bg-white">
                <h1 style={{fontWeight: '900'}} className="display-3 text-dark text-iom"><span className="text-danger">TECHNOLOGY</span> JOBS </h1>
                </div>
                
                <div class="card-body" style={{padding: '40px'}}>
                <div className="container">

                <div className="row">
                    <div className="col-xl-6 ">


                    <div class="card  border-primary  mb-3" style={{padding: '30px', height: '200px' }}>
                    <img class="card-img-top" style={{width: '220px'}} src="/logo.PNG" alt="Card image cap"/>
                    <div class="card-body">
                    <br/>
                        <p class="card-text">
                        <a href="/jobs/github" class="btn btn-primary"> Job Platfrom</a>
                        </p>
                    </div>
                    </div>

                   

                   
                    </div>
                    <div className="col-xl-6 ">
                  
                    <div class="card  border-primary  mb-3"  style={{padding: '30px'}}>
                    <img class="card-img-top" style={{width: '320px'}} src="/StackLogo.PNG" alt="Card image cap"/>
                    <div class="card-body">
                    <br/>
                    
                        <p class="card-text">
                        <a href="/jobs/stackoverflow" class="btn btn-primary">Job Platfrom</a>
                        </p>
                    </div>
                    </div>
                    </div>
                </div> 

                </div>
                    
                  
                    
                </div>
                <div className="row container mx-auto">
       
       <div className="row">
           <div className="col-xl-3 col-md-3 col-lg-3">
           <div class="card alert-info border-primary  mb-3 text-center">
           
           <div class="card-body text-primary">
               <h5 class="card-title">Sponsorship</h5>
               <img style={{width: '80px'}} src="/sponsor.svg" alt=""/>
               <br/>
               <br/>
               <a href="/jobs/sponsorship" className="btn btn-outline-dark">Browse Jobs</a>
           </div>
           </div>
           </div>

           <div className="col-xl-3 col-md-3 col-lg-3">
           <div class="card  border-primary mb-3 text-center">
           
           <div class="card-body text-success">
               <h5 class="card-title">Relocation</h5>
               <img style={{width: '80px'}} src="/travel.svg" alt=""/>
               <br/>
               <br/>
               <a href="/jobs/relocation" className="btn btn-dark">Browse Jobs</a>
           </div>
           </div>
           </div>


           <div className="col-xl-3 col-md-3 col-lg-3">
           <div class="card alert-primary border-primary mb-3 text-center">
           
           <div class="card-body text-dark">
               <h5 class="card-title">Remote</h5>
               <img style={{width: '80px'}} src="/remote.svg" alt=""/>
               <br/>
               <br/>
               <a href="/jobs/remote" className="btn btn-outline-dark">Browse Jobs</a>
           </div>
           </div>
           </div>

           <div className="col-xl-3 col-md-3 col-lg-3">
           <div class="card alert-warning border-primary mb-3 text-center">
           
           <div class="card-body text-danger">
               <h5 class="card-title">Equity</h5>
               <img style={{width: '80px'}} src="/equity.svg" alt=""/>
               <br/>
               <br/>
               <a href="/jobs/equity" className="btn btn-dark">Browse Jobs</a>
           </div>
           </div>
           </div>
       




       </div>


       
       
       <br/>
       </div>
            
                </div>
            </div>
          
        </div>

            
        
    )
}
